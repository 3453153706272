import React, { useState, useEffect} from 'react';
import {
  Box,
  VStack,
  // Heading,
  Text,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Input,
  Select,
  Flex,
  InputGroup,
  InputRightElement,
  Spinner,
  Tooltip
} from '@chakra-ui/react';

function EposList({ 
  data, 
  onPageChange, 
  onLinkUnlink, 
  availableTerminals = [], 
  onAddEpos,
  onSearch,
  onExport,
  onError,
  searchTerm,
  isLoading,
  onDelete
}) {
  const { isOpen: isDetailsOpen, onOpen: onDetailsOpen, onClose: onDetailsClose } = useDisclosure();
  const { isOpen: isLinkOpen, onOpen: onLinkOpen, onClose: onLinkClose } = useDisclosure();
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const [selectedEpos, setSelectedEpos] = useState(null);
  const [selectedTerminal, setSelectedTerminal] = useState('');
  const [isCurrentlyLinked, setIsCurrentlyLinked] = useState(false);
  const [newEposSerial, setNewEposSerial] = useState('');
  // const searchInputRef = useRef(null);
  // const [cursorPosition, setCursorPosition] = useState(null);
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

  useEffect(() => {
    if (selectedEpos && selectedTerminal) {
      // const selectedTerminalObject = availableTerminals.find(terminal => terminal.id === selectedTerminal);
      const linked = selectedEpos.terminals.some(terminal => terminal.id === selectedTerminal);
      setIsCurrentlyLinked(linked);
    }
  }, [selectedTerminal, selectedEpos, availableTerminals]);

  const handleViewDetails = (epos) => {
    setSelectedEpos(epos);
    onDetailsOpen();
  };

  const handleDelete = (epos) => {
    if (epos.terminals.length === 0) {
      onDelete(epos.id);
    } else {
      onError("Cannot delete EPOS. It is linked to one or more terminals.");
    }
  };


  const handleLinkUnlink = (epos) => {
    setSelectedEpos(epos);
    setSelectedTerminal('');
    setIsCurrentlyLinked(false);
    onLinkOpen();
  };

  const handleTerminalChange = (e) => {
    const terminalId = e.target.value;
    setSelectedTerminal(terminalId);
  };

  const handleLinkUnlinkConfirm = async () => {
    if (selectedTerminal) {
      try {
        await onLinkUnlink(selectedEpos.id, selectedTerminal, isCurrentlyLinked, true);
        onLinkClose();
      } catch (error) {
        console.error('Error during link/unlink operation:', error);
        onError('Failed to link/unlink. Please try again.');
      }
    }
  };

  const handleAddEpos = async () => {
    try {
      if (!newEposSerial.trim()) {
        throw new Error('EPOS Serial ID cannot be empty');
      }
      await onAddEpos(newEposSerial);
      setNewEposSerial('');
      onAddClose();
      // Optionally, you might want to refresh the list or show a success message
    } catch (error) {
      console.error('Error adding new EPOS:', error);
      onError(error.message || 'Failed to add new EPOS. Please try again.');
    }
  };


  // useLayoutEffect(() => {
  //   if (searchInputRef.current) {
  //     searchInputRef.current.focus();
  //     if (cursorPosition !== null) {
  //       searchInputRef.current.setSelectionRange(cursorPosition, cursorPosition);
  //     }
  //   }
  // }, [searchTerm, cursorPosition]);

  useEffect(() => {
    if (selectedEpos && selectedTerminal) {
      const linked = selectedEpos.terminals.some(terminal => terminal.id === selectedTerminal);
      setIsCurrentlyLinked(linked);
    }
  }, [selectedTerminal, selectedEpos]);

  // const handleSearch = (e) => {
  //   const currentCursorPosition = e.target.selectionStart;
  //   setCursorPosition(currentCursorPosition);
  //   onSearch(e.target.value);
  // };

  const handleSearchChange = (e) => {
    setLocalSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    onSearch(localSearchTerm);
  };


  const baseButtonStyle = {
    color: 'white',
    _hover: {
      transform: 'translateY(-2px)',
      boxShadow: 'md',
    },
    _active: {
      transform: 'translateY(0)',
      boxShadow: 'none',
    },
    transition: 'all 0.2s',
  };

  const eposButtonStyle = {
    ...baseButtonStyle,
    bg: 'blue.500',
    _hover: { ...baseButtonStyle._hover, bg: 'blue.600' },
    _active: { ...baseButtonStyle._active, bg: 'blue.700' },
  };

  const unlinkButtonStyle = {
    ...baseButtonStyle,
    bg: 'blue.800',
    _hover: { ...baseButtonStyle._hover, bg: 'green.600' },
    _active: { ...baseButtonStyle._active, bg: 'blue.800' },
  };

  const paginationButtonStyle = {
    ...baseButtonStyle,
    bg: 'gray.500',
    _hover: { ...baseButtonStyle._hover, bg: 'gray.600' },
    _active: { ...baseButtonStyle._active, bg: 'gray.700' },
  };


  return (
    <Box height="100%" display="flex" flexDirection="column" position="relative">
      <Box flex="1" overflowY="auto">
        {/* <Heading size="md" mb={4}>EPOS List</Heading> */}
        <HStack spacing={4} mb={4}>
          <Button onClick={onAddOpen} {...eposButtonStyle}>Add EPOS</Button>
          <Button onClick={onExport} {...eposButtonStyle}>Export EPOS</Button>
        </HStack>
        <InputGroup size="md" mb={3}>
          <Input 
            placeholder="Search EPOS by Serial ID" 
            value={localSearchTerm}
            onChange={handleSearchChange}
            pr="4.5rem"
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleSearchClick} {...eposButtonStyle}>
              Search
            </Button>
          </InputRightElement>
        </InputGroup>
        <VStack spacing={3} align="stretch" mb={16}>  {/* Added bottom margin */}
          {data.items.map((epos) => (
          <Box 
          key={epos.id} 
          p={3} 
          shadow="md" 
          borderWidth="1px" 
          borderRadius="md"
          transition="all 0.2s"
          _hover={{
            boxShadow: 'lg',
            transform: 'translateY(-2px)',
            bg: 'blue.50',
          }}
          >
          <Text fontWeight="bold">{epos.serial_id}</Text>
          <Text fontSize="sm">
            Linked Terminals: {epos.terminals.length > 0 
              ? epos.terminals.map(terminal => terminal.serial_id).join(', ') 
              : 'None'}
          </Text>
          <HStack mt={2}>
            <Button size="sm" onClick={() => handleViewDetails(epos)} {...eposButtonStyle}>View Details</Button>
            <Button size="sm" onClick={() => handleLinkUnlink(epos)} {...unlinkButtonStyle}>Link/Unlink</Button>
            <Tooltip label={epos.terminals.length > 0 ? "Cannot delete linked EPOS" : "Delete EPOS"}>
              <Button 
                size="sm" 
                onClick={() => handleDelete(epos)} 
                isDisabled={epos.terminals.length > 0}
                colorScheme="red"
              >
                Delete
              </Button>
            </Tooltip>
          </HStack>
        </Box>
          ))}
        </VStack>
      </Box>
      {data.items.length > 0 && (
        <Box 
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          borderTop="1px" 
          borderColor="gray.200" 
          py={2}
          px={4}
          bg="white"
          transform="translateY(-50%)"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Button 
              onClick={() => onPageChange(data.page - 1)} 
              isDisabled={data.page === 1}
              {...paginationButtonStyle}
              size="sm"
            >
              Previous
            </Button>
            <Text>{`Page ${data.page} of ${data.pages}`}</Text>
            <Button 
              onClick={() => onPageChange(data.page + 1)} 
              isDisabled={data.page === data.pages}
              {...paginationButtonStyle}
              size="sm"
            >
              Next
            </Button>
          </Flex>
        </Box>
      )}

      {/* Details Modal */}
      <Modal isOpen={isDetailsOpen} onClose={onDetailsClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>EPOS Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedEpos && (
              <VStack align="start">
                <Text><strong>Serial ID:</strong> {selectedEpos.serial_id}</Text>
                <Text><strong>Linked Terminals:</strong></Text>
                <VStack align="start" pl={4}>
                  {selectedEpos.terminals.length > 0 ? (
                    selectedEpos.terminals.map(terminal => (
                      <Text key={terminal.id}>{terminal.serial_id}</Text>
                    ))
                  ) : (
                    <Text>None</Text>
                  )}
                </VStack>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Link/Unlink Modal */}
      <Modal isOpen={isLinkOpen} onClose={onLinkClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Link/Unlink Terminal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select 
              placeholder="Select Terminal to link/unlink" 
              value={selectedTerminal} 
              onChange={handleTerminalChange}
            >
              {availableTerminals.map((terminal) => (
                <option key={terminal.id} value={terminal.id}>{terminal.serial_id}</option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme={isCurrentlyLinked ? "red" : "blue"}
              mr={3} 
              onClick={handleLinkUnlinkConfirm} 
              isDisabled={!selectedTerminal}
            >
              {isCurrentlyLinked ? 'Unlink' : 'Link'}
            </Button>
            <Button variant="ghost" onClick={onLinkClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add EPOS Modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New EPOS</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input 
              placeholder="Enter EPOS Serial ID" 
              value={newEposSerial}
              onChange={(e) => setNewEposSerial(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddEpos}>
              Add
            </Button>
            <Button variant="ghost" onClick={onAddClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    {/* </Box> */}
    </Box>
  );
}

export default EposList;

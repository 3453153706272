import React from 'react';
import { Text } from '@chakra-ui/react';

const EnvironmentLabel = () => {
  const environmentText = import.meta.env.VITE_DEPLOY_ENV === 'production' 
    ? 'Production Environment' 
    : 'Test Environment';

  return (
    <Text 
      fontWeight="bold" 
      color={import.meta.env.VITE_DEPLOY_ENV === 'production' ? 'red.500' : 'green.500'}
      bg={import.meta.env.VITE_DEPLOY_ENV === 'production' ? 'red.100' : 'green.100'}
      px={2}
      py={1}
      borderRadius="md"
    >
      {environmentText}
    </Text>
  );
};

export default EnvironmentLabel;
// TerminalList.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  // Heading,
  Text,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Input,
  Select,
  Flex,
  InputGroup,
  InputRightElement,
  Tooltip
} from '@chakra-ui/react';

function TerminalList({ 
  data, 
  onPageChange,
  onLinkUnlink, 
  availableEpos = [],
  onAddTerminal,
  onTerminalSearch,  // Changed from onSearch
  onExport,
  onError,
  terminalSearchTerm,  // Changed from searchTerm
  onDelete
}) {
  const { isOpen: isDetailsOpen, onOpen: onDetailsOpen, onClose: onDetailsClose } = useDisclosure();
  const { isOpen: isLinkOpen, onOpen: onLinkOpen, onClose: onLinkClose } = useDisclosure();
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectedEpos, setSelectedEpos] = useState('');
  const [isCurrentlyLinked, setIsCurrentlyLinked] = useState(false);
  const [newTerminalSerial, setNewTerminalSerial] = useState('');
  const [localSearchTerm, setLocalSearchTerm] = useState(terminalSearchTerm);


  // const searchInputRef = useRef(null);
  // const [cursorPosition, setCursorPosition] = useState(null);


  useEffect(() => {
    if (selectedTerminal && selectedEpos) {
      const selectedEposObject = availableEpos.find(epos => epos.id === selectedEpos);
      const linked = selectedTerminal.epos_list.includes(selectedEposObject?.serial_id);
      setIsCurrentlyLinked(linked);
    }
  }, [selectedEpos, selectedTerminal, availableEpos]);

  // useEffect(() => {
  //   if (selectedTerminal && selectedEpos) {
  //     // const linked = selectedTerminal.epos_list && selectedTerminal.epos_list.includes(selectedEpos);
  //     const linked = selectedTerminal.epos_list.some(epos_list => epos_list.id === selectedEpos);
  //     setIsCurrentlyLinked(linked);
  //   }
  // }, [selectedEpos, selectedTerminal, availableEpos]);

  // useLayoutEffect(() => {
  //   if (searchInputRef.current) {
  //     searchInputRef.current.focus();
  //     if (cursorPosition !== null) {
  //       searchInputRef.current.setSelectionRange(cursorPosition, cursorPosition);
  //     }
  //   }
  // }, [terminalSearchTerm, cursorPosition]);  // Changed from searchTerm


  const handleViewDetails = (terminal) => {
    setSelectedTerminal(terminal);
    onDetailsOpen();
  };

  const handleLinkUnlink = (terminal) => {
    setSelectedTerminal(terminal);
    setSelectedEpos('');
    setIsCurrentlyLinked(false);
    onLinkOpen();
  };

  const handleEposChange = (e) => {
    const eposId = e.target.value;
    setSelectedEpos(eposId);
    if (selectedTerminal) {
      const linked = selectedTerminal.epos_list.includes(eposId);
      setIsCurrentlyLinked(linked);
    }
  };

  const handleLinkUnlinkConfirm = async () => {
    if (selectedEpos) {
      try {
        await onLinkUnlink(selectedTerminal.id, selectedEpos, isCurrentlyLinked, false);
        onLinkClose();
      } catch (error) {
        console.error('Error during link/unlink operation:', error);
        onError('Failed to link/unlink. Please try again.');
      }
    }
  };

  const handleAddTerminal = async () => {
    try {
      if (!newTerminalSerial.trim()) {
        throw new Error('Terminal Serial ID cannot be empty');
      }
      await onAddTerminal(newTerminalSerial);
      setNewTerminalSerial('');
      onAddClose();
    } catch (error) {
      console.error('Error adding new terminal:', error);
      onError(error.message || 'Failed to add new terminal. Please try again.');
    }
  };

  // const handleSearch = (e) => {
  //   const term = e.target.value;
  //   onSearch(term);
  // };

  // const handleSearch = (e) => {
  //   const currentCursorPosition = e.target.selectionStart;
  //   setCursorPosition(currentCursorPosition);
  //   onTerminalSearch(e.target.value);  // Changed from onSearch
  // };

  const handleSearchChange = (e) => {
    setLocalSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    onTerminalSearch(localSearchTerm);
  };

  const baseButtonStyle = {
    color: 'white',
    _hover: {
      transform: 'translateY(-2px)',
      boxShadow: 'md',
    },
    _active: {
      transform: 'translateY(0)',
      boxShadow: 'none',
    },
    transition: 'all 0.2s',
  };

  const terminalButtonStyle = {
    ...baseButtonStyle,
    bg: 'green.500',
    _hover: { ...baseButtonStyle._hover, bg: 'green.600' },
    _active: { ...baseButtonStyle._active, bg: 'green.700' },
  };

  const unlinkButtonStyle = {
    ...baseButtonStyle,
    bg: 'green.900',
    _hover: { ...baseButtonStyle._hover, bg: 'blue.600' },
    _active: { ...baseButtonStyle._active, bg: 'green.800' },
  };

  const paginationButtonStyle = {
    ...baseButtonStyle,
    bg: 'gray.500',
    _hover: { ...baseButtonStyle._hover, bg: 'gray.600' },
    _active: { ...baseButtonStyle._active, bg: 'gray.700' },
  };

  const handleDelete = (terminal) => {
    if (terminal.epos_list.length === 0) {
      onDelete(terminal.id);
    } else {
      onError("Cannot delete Terminal. It is linked to one or more EPOS.");
    }
  };

  return (
    <Box height="100%" display="flex" flexDirection="column" position="relative">
      <Box flex="1" overflowY="auto">
        {/* <Heading size="md" mb={4}>Terminal List</Heading> */}
        <HStack spacing={4} mb={4}>
          <Button onClick={onAddOpen} {...terminalButtonStyle}>Add Terminal</Button>
          <Button onClick={onExport} {...terminalButtonStyle}>Export Terminals</Button>
        </HStack>
        <InputGroup size="md" mb={3}>
          <Input 
            placeholder="Search Terminal by Serial ID" 
            value={localSearchTerm}
            onChange={handleSearchChange}
            pr="4.5rem"
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleSearchClick} {...terminalButtonStyle}>
              Search
            </Button>
          </InputRightElement>
        </InputGroup>
        <VStack spacing={3} align="stretch" mb={16}>  {/* Added bottom margin */}
          {data && data.items && data.items.length > 0 ? (
            data.items.map((terminal) => (
              <Box 
              key={terminal.id} 
              p={3} 
              shadow="md" 
              borderWidth="1px" 
              borderRadius="md"
              transition="all 0.2s"
              _hover={{
                boxShadow: 'lg',
                transform: 'translateY(-2px)',
                bg: 'green.50',
              }}
            >
              <Text fontWeight="bold">{terminal.serial_id}</Text>
              <Text fontSize="sm">
                Linked EPOS: {terminal.epos_list && terminal.epos_list.length ? terminal.epos_list.join(', ') : 'None'}
              </Text>
              <HStack mt={2}>
                <Button size="sm" onClick={() => handleViewDetails(terminal)} {...terminalButtonStyle}>View Details</Button>
                <Button size="sm" onClick={() => handleLinkUnlink(terminal)} {...unlinkButtonStyle}>Link/Unlink</Button>
                <Tooltip label={terminal.epos_list.length > 0 ? "Cannot delete linked Terminal" : "Delete Terminal"}>
                  <Button 
                    size="sm" 
                    onClick={() => handleDelete(terminal)} 
                    isDisabled={terminal.epos_list.length > 0}
                    colorScheme="red"
                  >
                    Delete
                  </Button>
                </Tooltip>
              </HStack>
            </Box>
            ))
          ) : (
            <Text>No terminals found.</Text>
          )}
        </VStack>
      </Box>
      {data && data.items && data.items.length > 0 && (
        <Box 
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          borderTop="1px" 
          borderColor="gray.200" 
          py={2}
          px={4}
          bg="white"
          transform="translateY(-50%)"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Button 
              onClick={() => onPageChange(data.page - 1)} 
              isDisabled={data.page === 1}
              {...paginationButtonStyle}
              size="sm"
            >
              Previous
            </Button>
            <Text>{`Page ${data.page} of ${data.pages}`}</Text>
            <Button 
              onClick={() => onPageChange(data.page + 1)} 
              isDisabled={data.page === data.pages}
              {...paginationButtonStyle}
              size="sm"
            >
              Next
            </Button>
          </Flex>
        </Box>
      )}
      {/* Details Modal */}
      <Modal isOpen={isDetailsOpen} onClose={onDetailsClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terminal Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedTerminal && (
              <VStack align="start">
                <Text><strong>Serial ID:</strong> {selectedTerminal.serial_id}</Text>
                <Text><strong>Linked EPOS:</strong></Text>
                <VStack align="start" pl={4}>
                  {selectedTerminal.epos_list && selectedTerminal.epos_list.length > 0 ? (
                    selectedTerminal.epos_list.map((epos, index) => (
                      <Text key={index}>{epos}</Text>
                    ))
                  ) : (
                    <Text>None</Text>
                  )}
                </VStack>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Link/Unlink Modal */}
      <Modal isOpen={isLinkOpen} onClose={onLinkClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Link/Unlink EPOS</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select 
              placeholder="Select EPOS to link/unlink" 
              value={selectedEpos} 
              onChange={handleEposChange}
            >
              {availableEpos.map((epos) => (
                <option key={epos.id} value={epos.id}>{epos.serial_id}</option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme={isCurrentlyLinked ? "red" : "blue"}
              mr={3} 
              onClick={handleLinkUnlinkConfirm} 
              isDisabled={!selectedEpos}
            >
              {isCurrentlyLinked ? 'Unlink' : 'Link'}
            </Button>
            <Button variant="ghost" onClick={onLinkClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add Terminal Modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Terminal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input 
              placeholder="Enter Terminal Serial ID" 
              value={newTerminalSerial}
              onChange={(e) => setNewTerminalSerial(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddTerminal}>
              Add
            </Button>
            <Button variant="ghost" onClick={onAddClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </Box>
  );
}

export default TerminalList;
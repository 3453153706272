import React from 'react'
import ReactDOM from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import App from './App'
import './index.css'


// Log environment variables for debugging
console.log('Auth0 Domain:', __AUTH0_DOMAIN__)
console.log('Auth0 Client ID:', __AUTH0_CLIENT_ID__)
console.log('Auth0 Audience:', __AUTH0_AUDIENCE__)
console.log('API_URL:', __API_URL__)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Auth0Provider
      domain={__AUTH0_DOMAIN__}
      clientId={__AUTH0_CLIENT_ID__}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/dashboard`,
        audience: __AUTH0_AUDIENCE__
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
)
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Heading, SimpleGrid, Box, Spinner, Text, Button, Flex, Spacer, useToast, VStack } from '@chakra-ui/react';
import EposList from './EposList';
import TerminalList from './TerminalList';
import AnalyticsSection from './AnalyticsSection';
import { useAuth0 } from '@auth0/auth0-react';
import EnvironmentLabel from './EnvironmentLabel';

import { 
  fetchEpos, 
  fetchTerminals, 
  linkEposTerminal, 
  unlinkEposTerminal, 
  searchEpos,
  addEpos,
  exportEposData,
  searchTerminal,
  addTerminal,
  exportTerminalData,
  fetchAnalytics,
  deleteEpos,
  deleteTerminal
} from '../services/api';


function Dashboard() {
  const [eposData, setEposData] = useState({ items: [], total: 0, page: 1, pages: 1 });
  const [terminalData, setTerminalData] = useState({ items: [], total: 0, page: 1, pages: 1 });
  const [analyticsData, setAnalyticsData] = useState({
    totalEpos: 0,
    totalTerminals: 0,
    linkedEpos: 0,
    linkedTerminals: 0
  });
  const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(true);
  const [isLoadingEpos, setIsLoadingEpos] = useState(false);
  const [isLoadingTerminals, setIsLoadingTerminals] = useState(false);
  const [error, setError] = useState(null);
  const [eposSearchTerm, setEposSearchTerm] = useState('');
  const [terminalSearchTerm, setTerminalSearchTerm] = useState('');
  const toast = useToast();
  const { logout, user } = useAuth0();


  const loadAnalyticsData = useCallback(async () => {
    setIsLoadingAnalytics(true);
    try {
      const data = await fetchAnalytics();
      console.log("Fetched analytics data:", data);
      if (typeof data === 'object' && data !== null) {
        console.log("Analytics data details:", {
          totalEpos: data.totalEpos,
          totalTerminals: data.totalTerminals,
          linkedEpos: data.linkedEpos,
          linkedTerminals: data.linkedTerminals
        });
        setAnalyticsData(data);
      } else {
        console.error("Unexpected analytics data format:", data);
      }
    } catch (err) {
      console.error('Error fetching analytics data:', err);
      setError('Failed to fetch analytics data. Please try again later.');
    } finally {
      setIsLoadingAnalytics(false);
    }
  }, []);

  const loadEposData = useCallback(async (page = 1) => {
    setIsLoadingEpos(true);
    try {
      const response = await fetchEpos(page);
      setEposData(response);
    } catch (err) {
      console.error('Error fetching EPOS data:', err);
      setError('Failed to fetch EPOS data. Please try again later.');
    } finally {
      setIsLoadingEpos(false);
    }
  }, []);

  const loadTerminalData = useCallback(async (page = 1) => {
    setIsLoadingTerminals(true);
    try {
      const response = await fetchTerminals(page);
      setTerminalData(response);
    } catch (err) {
      console.error('Error fetching Terminal data:', err);
      setError('Failed to fetch Terminal data. Please try again later.');
    } finally {
      setIsLoadingTerminals(false);
    }
  }, []);
  

  useEffect(() => {
    loadAnalyticsData();
  }, [loadAnalyticsData]);


  const handleLinkUnlink = async (id1, id2, isLinked, isEpos) => {
    console.log('handleLinkUnlink called with:', { id1, id2, isLinked, isEpos });
    try {
      const eposId = isEpos ? id1 : id2;
      const terminalId = isEpos ? id2 : id1;
  
      if (isLinked) {
        console.log('Unlinking:', { terminalId, eposId });
        await unlinkEposTerminal(terminalId, eposId);
        toast({
          title: "Unlinked Successfully",
          description: `EPOS and Terminal have been unlinked.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.log('Linking:', { terminalId, eposId });
        await linkEposTerminal(terminalId, eposId);
        toast({
          title: "Linked Successfully",
          description: `EPOS and Terminal have been linked.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      // Refresh data after linking/unlinking
      await loadEposData(eposData.page);
      await loadTerminalData(terminalData.page);
    } catch (err) {
      console.error('Error linking/unlinking:', err);
      setError('Failed to link/unlink. Please try again.');
      toast({
        title: "Error",
        description: "Failed to link/unlink. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAdd = async (isEpos, newSerial) => {
    try {
      if (isEpos) {
        await addEpos({ serial_id: newSerial });
        await loadEposData(eposData.page);
      } else {
        await addTerminal({ serial_id: newSerial });
        await loadTerminalData(terminalData.page);
      }
      toast({
        title: `${isEpos ? 'EPOS' : 'Terminal'} Added`,
        description: `New ${isEpos ? 'EPOS' : 'Terminal'} with serial ${newSerial} has been added successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error adding new ${isEpos ? 'EPOS' : 'Terminal'}:`, error);
      setError(`Failed to add new ${isEpos ? 'EPOS' : 'Terminal'}. Please try again.`);
      toast({
        title: "Error",
        description: `Failed to add new ${isEpos ? 'EPOS' : 'Terminal'}. Please try again.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // const handleEposSearch = useCallback(async (searchTerm) => {
  //   setEposSearchTerm(searchTerm);
  //   setIsLoadingEpos(true);
  //   try {
  //     const searchResults = await searchEpos(searchTerm);
  //     setEposData(searchResults);
  //   } catch (error) {
  //     console.error('Error searching EPOS:', error);
  //     setError('Failed to search EPOS. Please try again.');
  //   } finally {
  //     setIsLoadingEpos(false);
  //   }
  // }, []);

  const handleEposSearch = useCallback(async (searchTerm) => {
    setEposSearchTerm(searchTerm);
    setIsLoadingEpos(true);
    try {
      if (searchTerm.trim() === '') {
        await loadEposData();
      } else {
        const searchResults = await searchEpos(searchTerm);
        setEposData(searchResults);
      }
    } catch (error) {
      console.error('Error searching EPOS:', error);
      setError('Failed to search EPOS. Please try again.');
    } finally {
      setIsLoadingEpos(false);
    }
  }, [loadEposData]);
  
  // }, [loadEposData]);

  const handleTerminalSearch = useCallback(async (searchTerm) => {
    setTerminalSearchTerm(searchTerm);
    setIsLoadingTerminals(true);
    try {
      const searchResults = await searchTerminal(searchTerm);
      setTerminalData(searchResults);
    } catch (error) {
      console.error('Error searching Terminal:', error);
      setError('Failed to search Terminal. Please try again.');
    } finally {
      setIsLoadingTerminals(false);
    }
  }, []);

  const handleExport = async (isEpos) => {
    try {
      const blob = isEpos ? await exportEposData() : await exportTerminalData();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${isEpos ? 'epos' : 'terminal'}_data.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      toast({
        title: "Export Successful",
        description: `${isEpos ? 'EPOS' : 'Terminal'} data has been exported successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error exporting ${isEpos ? 'EPOS' : 'Terminal'} data:`, error);
      setError(`Failed to export ${isEpos ? 'EPOS' : 'Terminal'} data. Please try again.`);
    }
  };

  const handleDelete = async (id, isEpos) => {
    try {
      if (isEpos) {
        await deleteEpos(id);
        await loadEposData(eposData.page);
      } else {
        await deleteTerminal(id);
        await loadTerminalData(terminalData.page);
      }
      toast({
        title: `${isEpos ? 'EPOS' : 'Terminal'} Deleted`,
        description: `${isEpos ? 'EPOS' : 'Terminal'} has been deleted successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error deleting ${isEpos ? 'EPOS' : 'Terminal'}:`, error);
      setError(`Failed to delete ${isEpos ? 'EPOS' : 'Terminal'}. It might be linked or an error occurred.`);
      toast({
        title: "Error",
        description: `Failed to delete ${isEpos ? 'EPOS' : 'Terminal'}. It might be linked or an error occurred.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box width="100%" minHeight="100vh" bg="gray.50" p={4}>
      <VStack spacing={6} align="stretch" width="100%">
      <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Heading size="lg" color="blue.600">UTP Terminal Linker Dashboard</Heading>
          <Flex alignItems="center">
            <Text mr={4}>Welcome, {user.name}</Text>
            <Box mr={4}>
            <EnvironmentLabel />
            </Box>
            <Button onClick={() => logout({ returnTo: window.location.origin })} colorScheme="blue" variant="outline">
              Log Out
            </Button>
          </Flex>
        </Flex>

        {isLoadingAnalytics ? (
          <Spinner size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />
        ) : (
          <Box width="100%">
            <AnalyticsSection analyticsData={analyticsData} />
          </Box>
        )}

    <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={6} width="100%">
      <Box bg="white" p={4} borderRadius="lg" boxShadow="md">
        <Heading size="md" mb={4} color="blue.600">EPOS List</Heading>
        <EposList
          data={eposData} 
          onPageChange={loadEposData}
          onLinkUnlink={handleLinkUnlink}
          availableTerminals={terminalData.items}
          onAddEpos={(newSerial) => handleAdd(true, newSerial)}
          onSearch={handleEposSearch}
          onExport={() => handleExport(true)}
          onError={setError}
          searchTerm={eposSearchTerm}
          isLoading={isLoadingEpos}
          onDelete={(id) => handleDelete(id, true)}
        />
      </Box>
      <Box bg="white" p={4} borderRadius="lg" boxShadow="md">
        <Heading size="md" mb={4} color="green.600">Terminal List</Heading>
        <TerminalList 
          data={terminalData} 
          onPageChange={loadTerminalData}
          onLinkUnlink={handleLinkUnlink}
          availableEpos={eposData.items}
          onAddTerminal={(newSerial) => handleAdd(false, newSerial)}
          onTerminalSearch={handleTerminalSearch}
          onExport={() => handleExport(false)}
          onError={setError}
          terminalSearchTerm={terminalSearchTerm}
          onDelete={(id) => handleDelete(id, false)}
        />
      </Box>
    </SimpleGrid>
      </VStack>
      {error && (
        <Box mt={4} p={4} bg="red.100" color="red.800" borderRadius="md">
          <Text fontWeight="bold">{error}</Text>
        </Box>
      )}
    </Box>
  );
}

export default Dashboard;
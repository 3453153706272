import React from 'react';
import { Box, SimpleGrid, Stat, StatLabel, StatNumber, StatHelpText, StatArrow, Icon } from '@chakra-ui/react';
import { StarIcon, RepeatIcon, LinkIcon, ViewIcon } from '@chakra-ui/icons';

function AnalyticsSection({ analyticsData }) {
  const statItems = [
    { label: 'Total EPOS', value: analyticsData.totalEpos, icon: StarIcon, color: 'blue.500' },
    { label: 'Total Terminals', value: analyticsData.totalTerminals, icon: ViewIcon, color: 'green.500' },
    { label: 'Linked EPOS', value: analyticsData.linkedEpos, icon: LinkIcon, color: 'purple.500' },
    { label: 'Linked Terminals', value: analyticsData.linkedTerminals, icon: RepeatIcon, color: 'orange.500' }
  ];

  return (
    <Box mb={8} bg="white" borderRadius="lg" boxShadow="lg" p={6}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
        {statItems.map((item, index) => (
          <Stat key={index} p={4} borderRadius="md" boxShadow="md" bg={`${item.color}10`}>
            <StatLabel fontWeight="medium" isTruncated>
              <Icon as={item.icon} mr={2} color={item.color} />
              {item.label}
            </StatLabel>
            <StatNumber fontSize="3xl" fontWeight="bold" color={item.color}>
              {item.value}
            </StatNumber>
            <StatHelpText>
              <StatArrow type={item.value > 0 ? 'increase' : 'decrease'} />
              {item.value > 0 ? `${((item.value / (analyticsData.totalEpos || analyticsData.totalTerminals)) * 100).toFixed(1)}%` : 'N/A'}
            </StatHelpText>
          </Stat>
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default AnalyticsSection;
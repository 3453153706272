import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Box, VStack, Heading , Text} from '@chakra-ui/react';
import EnvironmentLabel from './EnvironmentLabel';

function LoginPage() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <VStack
        spacing={8}
        bg="white"
        p={10} // Increased padding for more spacing
        borderRadius="lg" // Increased border-radius for a more rounded appearance
        boxShadow="xl" // Stronger shadow for a more pronounced raised effect
        width="full" // Ensures the box doesn't get too narrow on larger screens
        maxWidth="md" // Restricts the maximum width for better aesthetics
      >
        <EnvironmentLabel />
        <img src="/utplogo.png" alt="UTP Link Logo" />
        <Heading>UTP Link</Heading>
        <Text>Please log in to access the dashboard.</Text>
        <Button colorScheme="blue" size="lg" onClick={() => loginWithRedirect()}>
          Log In
        </Button>
      </VStack>
    </Box>
    );
  }

export default LoginPage;